import { template as template_42cee261b4164a37b78d6b69106b8e04 } from "@ember/template-compiler";
const FKLabel = template_42cee261b4164a37b78d6b69106b8e04(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
