import { template as template_36bf8bbff6d94e509a3506cc0d6d7e50 } from "@ember/template-compiler";
const FKControlMenuContainer = template_36bf8bbff6d94e509a3506cc0d6d7e50(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
